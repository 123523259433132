import React from "react";
import classNames from "classnames";
import { Container } from "@atoms";
import { m } from "framer-motion";

const IconGrid = ({ icons }) => {
  const container = {
    hidden: {
      opacity: 0,
      transition: {
        when: "afterChildren",
      },
    },
    show: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: "50%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      y: "0%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <Container>
      <m.ul
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ margin: "0px 0px -15% 0px" }}
        className="flex flex-wrap justify-center gap-y-10 gap-x-5"
      >
        {icons.map((i, idx) => (
          <m.li
            key={i.uid}
            variants={item}
            className="flex flex-col items-center justify-center gap-5 text-center md:flex-[0_1_calc(33.333%-.875rem)]"
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div
              className={classNames(
                "flex h-20 w-20 items-center justify-center rounded-full",
                {
                  "bg-teal": idx % 2 === 0,
                  "bg-purple": idx % 2 === 1,
                }
              )}
            >
              <div
                className="svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%] text-white"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: i.code }}
              />
            </div>
            <span className="whitespace-pre-line font-heading font-bold">
              {i.label}
            </span>
          </m.li>
        ))}
      </m.ul>
    </Container>
  );
};

export default IconGrid;
